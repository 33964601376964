import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { Table, Box, Flex } from "@mantine/core";
import { modals } from "@mantine/modals";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
import ScrollAreaLayout from "../../../widgets/ScrollAreaLayout";
import EmptyNotice from "../EmptyNotice";
import { IconCoinOff } from "@tabler/icons-react";
import SkeletonTable from "../../../pages/ResearcherPage/SkeletonTable";
import { getUnpaidUnits } from "../../../services/users.service";
import ToastWarning from "../../../toast/components/toastWarning/ToastWarning";

const UnpaidUnitModal = memo(() => {
  const TABLE_HEADERS = useMemo(
    () => ["Publication date", "videoId", "", "Amount"],
    []
  );
  const [scrolled, setScrolled] = useState(false);
  const [unpaidUnitsData, setUnpaidUnitsData] = useState({
    isLoading: true,
    data: {},
  });

  useEffect(() => {
    (async () => {
      const result = await getUnpaidUnits();

      if (result.error) {
        toast.error(result.error);
        modals.closeAll();
        return;
      }

      if (result.warning) {
        toast.custom(<ToastWarning text={result.warning} />);
        modals.closeAll();
        return;
      }

      if (result.data) {
        setUnpaidUnitsData((prev) => ({ ...prev, data: result.data }));
      }

      setUnpaidUnitsData((prev) => ({ ...prev, isLoading: false }));
    })();
  }, []);

  const onOpenVideoInfoPage = useCallback((videoId) => {
    window.open(`/library/${videoId}`, "_blank");
  }, []);

  return (
    <div className={styles.modal}>
      <Box className={styles.tableWrapper}>
        {unpaidUnitsData.isLoading ? (
          <SkeletonTable columnCount={7} rowCount={5} />
        ) : !unpaidUnitsData.data?.main?.length ? (
          <Flex
            justify="center"
            align="center"
            className={styles.emptyNoticeBlock}
          >
            <EmptyNotice
              icon={<IconCoinOff size="25px" color="black" />}
              text="It's empty here yet"
              color="black"
            />
          </Flex>
        ) : (
          <ScrollAreaLayout setScrolled={setScrolled} height={450}>
            <Table withColumnBorders withBorder className={styles.table}>
              <thead
                className={`${styles.header} ${
                  scrolled ? styles.scrolled : ""
                }`}
              >
                <tr>
                  {TABLE_HEADERS.map((title, index) => (
                    <th key={index}>{title}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {unpaidUnitsData.data.main.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td
                      className={styles.cursorPointer}
                      onClick={() => onOpenVideoInfoPage(item.videoId)}
                    >
                      {item.videoId}
                    </td>
                    <td>{item.dueFor}</td>
                    <td>{Math.round(item.earning.own)} $</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollAreaLayout>
        )}
      </Box>
    </div>
  );
});

export default UnpaidUnitModal;
